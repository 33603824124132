import React from "react";
import styled from "styled-components";
//
import { RichText } from "components/prismic";
//
export default ({ description, image, title, ...rest }) => (
  <Wrap>
    <Items>
      <img src={image.url} alt={title} />
      <Item>
        <Title>{title}</Title>
        {description && (
          <Text>
            <RichText {...description} />
          </Text>
        )}
      </Item>
    </Items>
  </Wrap>
);
//
const Wrap = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1350px;
  min-height: 100vh;
  min-height: calc(100vh - 136px - 320px);
  padding: 0;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const Items = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5rem;
  padding: 0.5rem;

  img {
    max-height: 50vh;
  }

  h1 {
    font-size: 2em;
  }

  @media (min-width: 769px) {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 1.3fr 1fr;
    margin:auto;
    padding: 0 1.5rem 1.5rem;

    h1 {
      font-size: 2.5em;
    }

    img {
      max-height: 70vh;
    }
`;

const Item = styled.div`
  align-self: start;
  padding: 1.5rem 0;

  p {
    margin: 0 0 1rem 0;

    strong {
      font-size: 1.1em;
    }
  }

  @media (min-width: 768px) {
    padding: 1.5rem 1.5rem 1.5rem 0;
  }

  @media (min-width: 2160px) {
    padding: 3rem 3rem 3rem 0;
  }
`;

const Title = styled.h1`
  font-weight: normal;
  margin: 0.5rem 0;
`;

const Text = styled.div`
  @media (min-width: 1025px) {
    max-width: 25vw;
  }
`;
